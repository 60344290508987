import React from "react"
import { graphql } from "gatsby"

import { Policy as Template } from "@components/Policy/Policy"

export const query = graphql`
  query($id: String) {
    policies: allSanityPolicyPage {
      edges {
        node {
          _id
          title
          slug {
            current
          }
        }
      }
    }
    page: sanityPolicyPage(_id: { eq: $id }) {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      title
      subtitle: _rawSubtitle(resolveReferences: { maxDepth: 10 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      accordions: _rawAccordions(resolveReferences: { maxDepth: 10 })
      settings {
        dark
        transparent
      }
      heroBanner: _rawHeroBanner(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }) => <Template {...props} {...data} />
