import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { Container } from "../Styled/Container"
import { P, H5 } from "../Styled/Text"

export const StyledContainer = tw(Container)`md:flex pt-8 md:pt-12 pb-8 md:pb-12 max-w-lg`

export const Aside = tw.nav`w-full md:w-1/4 md:pt-0-4`
export const Content = styled.div`
  ${tw`w-full md:w-3/4 md:pr-10`}
`

export const Heading = styled(H5)`
  ${tw`max-w-md text-center mb-0-8 text-center`}
  ${props => (props.hiddenSm ? tw`hidden md:block` : ``)}
`
export const Subheading = tw(P)`mb-1-2`

export const DesktopNavigation = tw.ul`hidden md:block`
export const MobileNavigation = tw.div`block md:hidden mb-3-2`
export const PolicyNavigationDropdown = tw.div`mt-2-4 mb-1-2`

export const NavigationLink = styled(Link)`
  ${tw`text-xs tracking-relaxed leading-loosest mb-0-1`}
  ${props => (props.active ? tw`underline` : tw`hover:underline`)}
`
