import React from "react"

import { useApp } from "@hooks/useApp"
import { useSection } from "@hooks/useSection"
import { useSanity } from "@hooks/useSanity"
import { useShopify } from "@hooks/useShopify"

export const withPolicy = Component => ({ name = `Policy`, location, page, policies }) => {
  const { setInvertedTheme, setTransparentTheme } = useApp()
  const { transformSection } = useSection()
  const { heroNormaliser, textNormaliser } = useSanity()
  const { edgeNormaliser } = useShopify()

  const content = {
    ...page,
    content: page?.content ? textNormaliser(page.content) : null,
    subtitle: page?.subtitle ? textNormaliser(page.subtitle) : null,
    heroBanner: heroNormaliser(page?.heroBanner),
    navigation: [
      ...edgeNormaliser(policies).map(policy => ({
        title: policy?.node?.title,
        handle: policy?.node?.slug?.current,
        url: policy?.node?.slug?.current ? `${config.settings.routes.POLICY}/${policy?.node?.slug?.current}` : null,
      })),
    ],
    accordions:
      page?.accordions?.length > 0
        ? [
            ...page.accordions.map(accordion => ({
              ...accordion,
              title: textNormaliser(accordion.title),
              content: textNormaliser(accordion.content),
            })),
          ]
        : [],
  }
  const hero = transformSection(content.heroBanner)

  if (content?.settings) {
    setInvertedTheme(content.settings.dark)
    setTransparentTheme(content.settings.transparent)
  }

  Component.displayName = name
  return <Component content={content} hero={hero} location={location} />
}
