import React from "react"
import { navigate } from "gatsby"

import { withPolicyNavigation } from "./withPolicyNavigation"
import { Dropdown } from "../../Styled/Dropdown/Dropdown"
import { NavigationLink, Heading, Subheading, DesktopNavigation, MobileNavigation, PolicyNavigationDropdown } from "../PolicyStyles"

interface Props {
  active: boolean
  navigation: any
  title: string
}

export const PolicyNavigation = withPolicyNavigation(({ active, navigation, title }: Props) => (
  <>
    <DesktopNavigation>
      <Subheading>{title}</Subheading>

      <ul>
        {navigation
          .filter(link => !link.hidden)
          .map(link => (
            <li key={link.title}>
              <NavigationLink active={active?.title === link.title ? `true` : undefined} to={link.url}>
                {link.title}
              </NavigationLink>
            </li>
          ))}
      </ul>
    </DesktopNavigation>

    <MobileNavigation>
      <Heading>{title}</Heading>

      <PolicyNavigationDropdown>
        <Dropdown
          onChange={value => navigate(value)}
          options={navigation.map(link => ({ label: link.title, value: link.url }))}
          value={active?.url}
        />
      </PolicyNavigationDropdown>
    </MobileNavigation>
  </>
))
