import React from "react"

import { withPolicy } from "./withPolicy"
import { HeroBanner } from "../Sections/HeroBanner/HeroBanner"
import { PolicyContent } from "./Content/PolicyContent"
import { PolicyNavigation } from "./Navigation/PolicyNavigation"
import { Aside, Content, StyledContainer } from "./PolicyStyles"

interface Props {
  content: any
  hero: any
  location: string
}

export const Policy = withPolicy(({ content, hero, location }: Props) => (
  <>
    {hero ? <HeroBanner section={hero} condensed={`true`} /> : null}

    <StyledContainer as={`section`}>
      <Aside>
        <PolicyNavigation location={location} navigation={content.navigation} title={content.subtitle} />
      </Aside>

      <Content>
        <PolicyContent accordions={content.accordions} content={content.content} />
      </Content>
    </StyledContainer>
  </>
))
